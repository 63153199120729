import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

export interface VideoInfo {
  title: string
  titleHindi?: string
  length: string
  link: string
  thumbnail?: IGatsbyImageData
}

export const pokerVideos: VideoInfo[] = [
  {
    title: 'How to Play Online Poker on Mega Poker',
    titleHindi: 'मेगा पोकर पर पोकर कैसे खेलें',
    length: '9:02',
    link: 'https://player.vimeo.com/video/871018617?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
]

export const rummyVideos: VideoInfo[] = [
  {
    title: 'How To Play Rummy On Mega Rummy',
    titleHindi: 'मेगा रम्मी पर रम्मी कैसे खेलें',
    length: '9:39',
    link: 'https://player.vimeo.com/video/848959761?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
]

const carromVideos: VideoInfo[] = [
  {
    title: 'How to play Carrom',
    length: '1:29',
    link: 'https://player.vimeo.com/video/548346349?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
  {
    title: 'Tanmay Bhat Mega Carrom Party',
    length: '2:27:54',
    link: 'https://player.vimeo.com/video/551409650?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
]

const poolVideos: VideoInfo[] = [
  {
    title: 'How to play GoPool',
    length: '1:38',
    link: 'https://player.vimeo.com/video/548348756?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
]

const gangsOfGamersVideos: VideoInfo[] = [
  {
    title: 'Mega: Install, Play, Win, Withdraw, Repeat!️',
    length: '0:45',
    link: 'https://player.vimeo.com/video/577600602?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
  {
    title: 'Mega Rummy️',
    length: '0:40',
    link: 'https://player.vimeo.com/video/577599725?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
  {
    title: 'Rummy On Mega️',
    length: '0:49',
    link: 'https://player.vimeo.com/video/577601590?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
  {
    title: 'Mega Pe Rummy️',
    length: '0:21',
    link: 'https://player.vimeo.com/video/577602755?title=0&byline=0&portrait=0&autoplay=1&background=0',
  },
]

const useVideos = (): {
  pokerVideos: VideoInfo[]
  rummyVideos: VideoInfo[]
  carromVideos: VideoInfo[]
  poolVideos: VideoInfo[]
  gangsOfGamersVideos: VideoInfo[]
} => {
  const thumbnails = useStaticQuery(graphql`
    {
      p1: file(
        relativePath: { eq: "tutorial-thumbnails/mega-poker-video.webp" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
      r1: file(
        relativePath: {
          eq: "tutorial-thumbnails/rummy-tutorial-thumbnail-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 640, layout: CONSTRAINED)
        }
      }
      r2: file(
        relativePath: {
          eq: "tutorial-thumbnails/rummy-tutorial-thumbnail-2.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 640, layout: CONSTRAINED)
        }
      }
      r3: file(
        relativePath: {
          eq: "tutorial-thumbnails/rummy-tutorial-thumbnail-3.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 640, layout: CONSTRAINED)
        }
      }
      carrom1: file(
        relativePath: { eq: "tutorial-thumbnails/carrom-tutorial-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 460, layout: CONSTRAINED)
        }
      }
      carrom2: file(
        relativePath: { eq: "thumbnails/Tanmay-Bhatt-Mega-Carrom-Party.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 460, layout: CONSTRAINED)
        }
      }
      pool1: file(
        relativePath: { eq: "tutorial-thumbnails/pool-tutorial-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 460, layout: CONSTRAINED)
        }
      }
      gang1: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 460, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `)

  return {
    pokerVideos: pokerVideos.map((p, i) => ({
      ...p,
      thumbnail: thumbnails[`p${i + 1}`].childImageSharp.gatsbyImageData,
    })),
    rummyVideos: rummyVideos.map((p, i) => ({
      ...p,
      thumbnail: thumbnails[`r${i + 1}`].childImageSharp.gatsbyImageData,
    })),
    carromVideos: carromVideos.map((p, i) => ({
      ...p,
      thumbnail: thumbnails[`carrom${i + 1}`].childImageSharp.gatsbyImageData,
    })),
    poolVideos: poolVideos.map((p, i) => ({
      ...p,
      thumbnail: thumbnails[`pool${i + 1}`].childImageSharp.gatsbyImageData,
    })),
    gangsOfGamersVideos: gangsOfGamersVideos.map(p => ({
      ...p,
      thumbnail: thumbnails.gang1.childImageSharp.gatsbyImageData,
    })),
  }
}

export default useVideos
