import React from 'react'
import './HelpCenter.scss'
import { Link } from 'gatsby'
import { Col, Container, Row } from 'react-bootstrap'
import GSCard from './GettingStarted/GSCard'
import faqs from '../FAQs/faqs-list'
import FAQ from '../FAQs/FAQ'
import Videos from '../Games/Cards/Poker/Videos'
import useCardsList from './useHelpCenterCardsList'
import useVideos from '../Games/videos-list'
import useFaqExpansion from '../../hooks/useFaqExpansion'
import { CONTACT_US, FAQS } from '../internal-links'

const HelpCenter: React.FC = () => {
  const cardsList = useCardsList()
  const { pokerVideos, rummyVideos } = useVideos()
  const { expandedFaq, setExpandedFaq } = useFaqExpansion()

  return (
    <div id="HelpCenter">
      {/* <Container>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Container> */}

      <Container>
        <h1>
          <span className="underline">Help</span> Centre
        </h1>
      </Container>

      <section className="links">
        <Container>
          <Row xs={1} md={2} lg={3}>
            {cardsList.map(c => {
              return (
                <Col key={c.title} className="gs-col">
                  <Link to={c.link}>
                    <GSCard title={c.title} content={c.content} img={c.img} />
                  </Link>
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>

      <section className="faqs">
        <Container>
          <Row>
            <Col md={9}>
              <h3>Top FAQ’s</h3>
              <div className="list">
                {faqs.map((f, i) => {
                  return (
                    <React.Fragment key={f.question}>
                      <FAQ
                        question={f.question}
                        answer={f.answer}
                        answerElement={f.answerElement}
                        expanded={expandedFaq === i}
                        setExpanded={setExpandedFaq}
                        questinNumber={i}
                      />

                      {i !== faqs.length - 1 ? (
                        <div className="separator" />
                      ) : null}
                    </React.Fragment>
                  )
                })}
              </div>
              <Link to={FAQS} className="button d-sm-none">
                GO TO FAQ PAGE
                {/* <Img loading="eager"
                  fluid={data.l.childImageSharp.fluid}
                  className="link-img"
                  imgStyle={{ objectFit: 'contain' }}
                /> */}
              </Link>
              <Link to={CONTACT_US} className="button d-sm-none">
                CONTACT US
                {/* <Img loading="eager"
                  fluid={data.l.childImageSharp.fluid}
                  className="link-img"
                  imgStyle={{ objectFit: 'contain' }}
                /> */}
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="videos">
        <Videos
          videos={[...pokerVideos, ...rummyVideos]}
          title={
            <>
              <span className="underline">Learn more</span> through videos
            </>
          }
        />
      </section>

      <section className="contact-us d-sm-none">
        <Container>
          <h3>Couldn’t find what you were looking for ?</h3>
          <Link to={CONTACT_US} className="button">
            CONTACT US
            {/* <Img loading="eager"
              fluid={data.l.childImageSharp.fluid}
              className="link-img"
              imgStyle={{ objectFit: 'contain' }}
            /> */}
          </Link>
        </Container>
      </section>
    </div>
  )
}

export default HelpCenter
