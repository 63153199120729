import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import './Videos.scss';
import { Col, Container, Row } from 'react-bootstrap';
import FullscreenVideo from '../../../FullscreenVideo/FullscreenVideo';
import { VideoInfo } from '../../videos-list';
import Videothumbline from '../../../../images/videologo.png';

interface Props {
  videos: VideoInfo[];
  title?: React.ReactNode;
  showTitle?: boolean;
  hideTitle?: boolean; // New prop to control title visibility
}

const Videos: React.FC<Props> = ({ videos, title, showTitle = true, hideTitle = false }) => {
  const data = useStaticQuery(graphql`
    {
      play: file(relativePath: { eq: "play-circle.png" }) {
        childImageSharp {
          gatsbyImageData(width: 120, layout: CONSTRAINED)
        }
      }
    }
  `);

  const [showVideo, setShowVideo] = useState(false);
  const [videoLink, setVideoLink] = useState('');

  return (
    <Container>
      <Row>
        <Col className="videos">
          {showTitle && !hideTitle && (
            <h2 className="text">
              {title || 'Learn How to Play Rummy on Mega Rummy'}
            </h2>
          )}

          <div className={`list s${videos.length}`}>
            {videos.map((v) => {
              return (
                <div className="video" key={v.title}>
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={(): void => {
                      setVideoLink(v.link);
                      setShowVideo(true);
                    }}
                    onKeyDown={(e): void => {
                      if (e.key === 'Enter') {
                        setVideoLink(v.link);
                        setShowVideo(true);
                      }
                    }}
                    title={v.title}
                    className="video-button"
                  >
                    <div className="video-thumb-wrap">
                      <img
                        src={Videothumbline} // Use the static folder path
                        alt="video thumbnail placeholder"
                        className="video-thumbnail"
                        style={{ objectFit: 'contain', width: '100%' }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {showVideo && (
            <FullscreenVideo
              close={() => {
                setShowVideo(false);
              }}
              src={videoLink}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Videos;
