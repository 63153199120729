import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import HelpCenterContent from '../../components/HelpCenter/HelpCenter'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { HELP_CENTRE as HELP_CENTRE_LINK } from '../../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import faqs from '../../components/FAQs/faqs-list'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Help Centre',
    url: HELP_CENTRE_LINK,
  },
]

const HelpCenter: React.FC = () => {
  return (
    <Layout
      breadcrumbs={breadcrumbs}
      showBreadcrumbs
      desktopBreadcrumbColor="black"
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Help Centre: Get Help on Your Queries Across Six Categories | Mega"
        description="Visit our help centre and read about real monye gaming and much more. Queries are segmented in six broad categories to provide answers to all your questions!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        faqSchema={generateFaqSchema(faqs)}
      />
      <HelpCenterContent />
    </Layout>
  )
}

export default HelpCenter
